.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    background-color: #136225;
    color: white;
    font-size: 14px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
  
  .marquee-content {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 25s linear infinite;
   
  }

  .marquee-content a
  {
    text-decoration: none;
    color: white;
  }

  .marquee-link
  {
    color: white;
    font-weight: 700;
    text-decoration: underline;
  }

  .marquee-content:hover
  {
    animation-play-state: paused;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  