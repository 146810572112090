.contactuscards-container
{
    height: auto;
    background-color: #f4f8fc;
    padding-top: 5rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 3rem;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.contactuscards-box
{
    height: 320px;
    width: 320px;
    background-color: white;
    position: relative;
    border-radius: 15px;
}

.contactuscards-box-topcontainer
{
    position: absolute;
    top: -16px;
    left: 57px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 40px;
    width: 200px;
    border-radius: 25px;
    color: #137229;
    font-weight: 600;
    gap: 12px;
    box-shadow: 0px 0px 13px -4px #0000002e;
}

.contactuscards-box-topcontainer-img img
{
    width: 20px;
    padding-top: 5px;
}

.contactuscards-box-lowercontainer
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactuscards-box-lowercontainer-content
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.contactuscards-box-lowercontainer-content_top
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contactuscards-box-lowercontainer-content-heading
{
    font-size: 1.8rem;
    font-weight: 700;
    padding-bottom: 5px;
}

.contactuscards-box-lowercontainer-content-txt
{
    font-weight: 500;
    text-align: center;
    padding-bottom: 4px;
}

.contactuscards-box-lowercontainer-content-txt a{
    color: black;
    text-decoration: none;
}

.contactuscards-box-lowercontainer-content-address
{
    font-weight: 500;
    text-align: center;
    width: 85%;
    line-height: 23px;
}

.contactuscards-box-lowercontainer-content-address a
{
    color: black;
    text-decoration: none;
}

.contactuscards-box-lowercontainer-content_lower img
{
    height: 100px;
}

@media (max-width:1150px) {
    .contactuscards-container
    {
        flex-direction: column;
        gap: 5rem;
    }
}

@media (max-width:350px) {
    .contactuscards-box
{
    height: 290px;
    width: 290px;
}
}