.error-container
{
    height: 85vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.error-heading
{
    font-size: 7rem;
    font-weight: 800;
}

.error-title
{
    font-size: 2.4rem;
    font-weight: 600;
}