.nav-container
{
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5rem 0;
    flex-wrap: wrap;
}

@media (max-width:1500px) {
    .nav-container
    {
        gap: 4rem;
        padding: 5rem 1rem;
    }
}


