.heading-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.6rem 0;
    gap: 0.5rem;

}

.pageheading
{
    font-size: 3rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}

.heading-line
{
    height: 4px;
    background-color: black;
    width: 90%;
}
.linewidth
{
    width: 100%;
}
@media (max-width:450px) {
    .pageheading
{
    font-size: 2.5rem;
    width: 80%;

}
}