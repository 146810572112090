.shopping-card-conatainer {
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    width: 100%;
    height: auto;
}

@media (max-width:500px) {
    .shopping-card-conatainer {
        padding: 2rem 0rem;

    }
}