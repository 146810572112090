.navi-container {
    height: auto;
    width: 350px;
    box-shadow: 0px 0px 10px -2.5px rgba(0, 0, 0, 0.155);
    border-radius: 20px;
    transition: all 0.4s ease-in-out;
    background-color: white;
    cursor: pointer;
    padding: 0.1rem;
}

.navi-box {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    margin: 24px;
}


.navi-box img {
    height: 210px;
    width: 315px;
    border-radius: 10px;
    pointer-events: none;

}

.navi-title {
    font-size: 1.3rem;
    font-weight: 800;
    text-transform: uppercase;
}

@media (max-width:400px) {
    .navi-container {
        width: 320px;
    }

    .navi-box img {
        height: 200px;
        width: 275px;
        border-radius: 10px;

    }
}