.social-media-form-container
{
    height: auto;
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 5rem;
    background-color: rgb(241, 246, 250);
}

.social-media-form-icons
{
    border: 2px solid black;
    border-radius: 50%;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.social-media-form-icons:hover
{
    filter: invert(2);
    background-color: white;
}

.social-media-form-icons img
{
    height: 25px;
}

@media (max-width:600px) {
    .social-media-form-container
{
    flex-wrap: wrap;
}
}