.session-heading
{
    font-size: 1.3rem;
    font-weight: 600;
    padding-top: 2rem;
    /* padding-left: 2rem; */
    padding-bottom: 1rem;
}

@media (max-width:1000px) {
    .session-heading
    {
        font-size: 1.1rem;
        padding-top: 1rem;
        padding-left: 0rem;
        padding-bottom: 0.8rem;
    }
}

@media (max-width:500px) {
    .session-heading
    {
        font-size: 1.1rem;
        padding-top: 1rem;
        padding-left: 0rem;
        padding-bottom: 0.8rem;
    }
}