.top-heading-subpage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    font-size: 2.5rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
}

.subpage-heading-line {
    height: 3px;
    background-color: black;
    margin-top: 10px;
    width: 90%;
    text-align: center;
}

@media (max-width:1000px) {
    .top-heading-subpage {
        padding-top: 1rem;
        font-size: 2rem;
        font-weight: 700;
        width: 100%;
    }
}

@media (max-width:500px) {
    .top-heading-subpage {
        padding-top: 1rem;
        font-size: 1.4rem;
        font-weight: 700;
        width: 100%;
    }
}