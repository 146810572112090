
:root{
    --anim-time--hi: 266ms;
    --anim-time--med: 400ms;
    --anim-time--lo: 600ms;
}

.gallery-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* flex-direction: row; */
    width: auto;
    height: auto; 
    padding: 5rem 0;
    gap: 3rem;
}

.gallery-container-inner
{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-wrap: wrap;
    /* flex-direction: row; */
    
}

figure {
    display: flex;
   width: 400px;
    position: relative;
    border-radius: 15px;
    /* box-shadow:
    rgb(40, 40, 40, 0.1) 0px 2px 3px,
    rgb(20, 20, 20, 0.2) 0px 5px 8px,
    rgb(0, 0, 0, 0.25) 0px 10px 12px; */
    overflow: hidden;
    transition: transform var(--anim-time--med) ease;
}

figure::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
        to top,
        hsla(0, 0%, 0%, 0.8) 0%,
        hsla(0, 0%, 0%, 0.7) 12%,
        hsla(0, 0%, 0%, 0.2) 41.6%,
        hsla(0, 0%, 0%, 0.125) 50%,
        hsla(0, 0%, 0%, 0.01) 59.9%,
        hsla(0, 0%, 0%, 0) 100%
    );
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: var(--anim-time--med), var(--anim-time--med);
    transition-timing-function: ease, ease;
      z-index: 4;
}

.header__caption {
    z-index: 7;
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    gap: 0.5rem;
    padding: 1rem;
    justify-content: center;
    text-align: center;
    /* transform: translateY(100%);
    transition: transform var(--anim-time--hi) linear,
    opacity var(--anim-time--hi) linear; */
}

figure:hover::before {
    opacity: 0.8;
}

figure .header__caption {
    transform: translateY(0);
    opacity: 1;
}
figure:hover img{
    transform: scale(1);
}
.title {
    color: #fff;
    
}

.title--primary {
    font-size: 1.25rem;
    font-weight: bold;
}

.title--secondary {
    text-transform:uppercase;
    font-weight: bold;
}

figure img {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
  transform: scale(1.15);
    aspect-ratio: 16 / 13;
    transition: 400ms ease-in-out;
}

@media (max-width:485px) {
    figure   
    {
        width: 330px;
    }
}

@media (max-width:355px) {
    figure   
    {
        width: 290px;
    }
}
