.sep-line {
    width: 100%;
    height: 1px;
    background-color: black;
}

.footer-container {
    height: auto;
    background-color: rgb(241, 246, 250);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%
}

.footer-top-box {
    padding-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    gap: 4rem;
    padding-left: 6rem;
    padding-right: 4rem;
}

.footer-logo {
    background-color: rgb(241, 246, 250);

}


.footer-logo-img img {
    width: 200px;
    pointer-events: none;
}

.logo-title {
    font-size: 18px;
    font-weight: 700;
    padding-top: 10px;
    text-align: center;
}



.footer-col {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 1rem;
    width: auto;
}

.footer-col-group {
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: row;
    width: 100%;
}

.footer-col-subgroup {
    display: flex;
    justify-content: space-around;
    align-items: start;
    flex-direction: row;
    width: 100%;
}

.footer-middle-box {
    height: 3px;
    background-color: rgba(0, 0, 0, 0.888);
    width: 90%;
    margin: 2rem 0;
}

.footer-buttom-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 20vh;
    padding-bottom: 30px;

}

.socialmedialogos {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 1rem;
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 50%;
    padding: 5px;
    height: 40px;
    width: 40px;
    transition: all 0.3s ease-in-out;
}

.circle:hover
{
    filter: invert(2);
    background-color: white;
}

.copyright-text {
    font-size: 17px;
    font-weight: 700;
    padding-top: 10px;
}

.footer-heading {
    font-size: 18px;
    font-weight: 700;
    
}

.footer-link
{
    color: black;
    text-decoration: none;
}

.footer-text {
    color: black;
    font-size: 16px;
    cursor: pointer;
}

.footer-text:hover {
    color: #137229;
}

.circle img
{
    height: 24px;
}

.footer-phoneno a
{
    font-weight: 600;
    text-decoration: none;
    color: black;
}

.footer-Email a
{
    font-weight: 600;
    text-decoration: none;
    color: black;
}

@media (max-width:1250px) {
    .footer-top-box {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media (max-width:850px) {
    .footer-col-group {
        justify-content: space-evenly;
        align-items: start;
        flex-direction: column;
        width: 100%;
        gap: 3rem;
    }

    .footer-col {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
}

@media (max-width:500px)
{
    .footer-col-subgroup {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
    }

    .copyright-text {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        padding-top: 20px;
    }

    .footer-col
    {
        width: auto;
    }
}