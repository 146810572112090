.blank-box
{
    height: 90px;
}


.herosection-container
{
    height: 90vh;
    width: 100%;
}

.hero-box
{
    height: 100%;
    background-image: url(./img/hero\ image.avif);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-title
{
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 700;
    color: white;
    height: auto;
    text-wrap:wrap;
    width: 100%;
    text-align: center;
}

@media (max-width:850px) {
    .hero-title
    {
        font-size: 2.5rem;
    }
}

@media (max-width:625px) {
    .hero-title
    {
        font-size: 2rem;
    }
}