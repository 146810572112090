@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  user-select: none;
}

.pvd-container {
  height: 100vh;
  width: 100%;
}

img,
video {
  max-width: 2000px;
  height: auto;
}

/* scroll bar styling*/

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #137229;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* tostify css */

.toastBody {
  font-family: "Atlas Grotesk Web", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
}
