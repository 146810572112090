.traininghero-container
{
    height: 100vh;
    background-image: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)),url(./img/traininghero.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}


.aboutus-heading
{
    font-size: 6rem;
    color: white;
    font-weight: 600;
    font-family: sans-serif;
}

@media (max-width:1000px)
{
    .traininghero-container
    {
        height: 60vh;
    }
}


@media (max-width:840px) {
   
    .aboutus-heading
{
    font-size: 5rem;
}
}

@media (max-width:550px) {
    .aboutus-heading
{
    font-size: 4rem;
}
.hero-container
{
    height: 50vh;
}
}

@media (max-width:380px) {
    .aboutus-heading
{
    font-size: 3rem;
}
}