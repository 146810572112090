.dropdown-container
{
    display: flex;
    justify-content: space-between;
    align-items: start;
    height: 100%;
    width: 100%;
    padding: 3rem;
}

.dropdown-col
{
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 1.1rem;
    flex-direction: column;
}

.dropdown-heading
{
    font-size: 25px;
    font-weight: 700;
}

.dropdown-subheading-title
{
    font-size: 10px;
    font-weight: 100;
    padding: 3px;
}

.dropdown-subheading
{
    font-size: 17px;
}

.hov
{
    color: black;
    text-decoration: none;
}

.hov:hover
{
    color: #137229;
}

 .hov > .active 
{
    color: #000000;
    text-decoration: none;
    
}

.dropdown-subpart li
{
   padding-top: 15px;
   padding-left: 50px;
}

