.main-container {
    height: 370px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    padding: 30px;
    /* filter: drop-shadow(10px 10px 11px silver); */
    /* border: 2px solid rgb(0, 0, 0); */
    background-color: rgba(239, 235, 235, 0.701);
    border-radius: 10px;
    margin: 1rem 6rem;
    margin-bottom: 5rem;
    margin-top: 4rem;
}

.main-container-left {

    flex-direction: row;

}

.main-container-right {
    flex-direction: row-reverse;
}


.image-box-container img {
    border-radius: 10px;
    width: 440px;
    object-fit: contain;
    pointer-events: none;
}

.contant-box
{
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
    height: 100%;
    padding: 1rem 0;
}

.contant-box-left {
    padding-right: 2rem;
}

.contant-box-right {
 
    padding-left: 0.8rem;
}

.contant-heading {
    font-size: 34px;
    font-weight: 700;
    padding-bottom: 10px;
}

.text-contant {
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 137.3%;
    letter-spacing: .015em;
    color: #222;
    padding-bottom: 31px;
    text-align: justify;
}

.container-btn
{
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
}

.container-btn-left {

    justify-content: end;
  
}

.container-btn-right {
    justify-content: start;

}

.btn button {
    width: 140px;
    height: 40px;
    border: 0;
    font-size: 0.9rem;
    border-radius: 13px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
}

.btn1 button {
    background-color: black;
    color: white;
    border: 0;
}

.btn2 button {
    background-color: rgba(245, 241, 241, 0);
    color: black;
    border: 3px solid black;
}


@media (max-width:1300px) {

    .main-container {
        height: auto;
    }

    .image-box-container img {
        width: 440px;
    }

    .contant-heading {
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 10px;
    }

    .text-contant {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 137.3%;
        letter-spacing: .015em;
        color: #222;
        padding-bottom: 31px;
    }
}

@media (max-width:1240px) {

    .image-box-container img {
        width: 440px;
    }
}

@media (max-width:1140px) {

    .main-container {
        height: auto;
        flex-direction: column;
    }

    .image-box-container img {
       width: 100%;
    }

    .main-container
    {
        margin: 1rem 4rem;
        margin-bottom: 5rem;
        margin-top: 4rem;
    }

    .contant-box-left {
        padding-right: 0rem;
    }
    
    .contant-box-right {
     
        padding-left: 0rem;
    }

    .contant-box
    {
        align-items: center;
        gap: 0.5rem;
    }

    .container-btn
    {
        justify-content: end;
    }

    .contant-heading
    {
        padding-bottom: 20px;
        text-align: center;
    }

    .text-contant
    {
        text-align: justify;
        padding: 0 10px;
        padding-bottom: 2.5rem;
    }
}



@media (max-width:650px)
{
    .main-container
    {
        margin: 1rem 3rem;
        margin-bottom: 5rem;
        margin-top: 1rem;
    }

    .btn2 button {
        background-color: rgba(245, 241, 241, 0);
        color: black;
        border: 1px solid black;
    }
}

@media (max-width:530px)
{
    .main-container
    {
        margin: 1rem 2.2rem;
        margin-bottom: 5rem;
    }
    .container-btn
    {
        justify-content: center;
    }
}

@media (max-width:480px)
{
    .main-container
    {
        margin: 1rem 1.5rem;
        margin-bottom: 5rem;

    }

    .container-btn
    {
        gap: 1.4rem;
    }
  
}

@media (max-width:415px)
{

    .btn button {
        width: 130px;
        height: 35px;
        font-size: 0.8rem;
        border-radius: 13px;
        cursor: pointer;
        font-weight: 600;
    }
}

@media (max-width:375px)
{
    .main-container
    {
        margin: 1rem ;
        margin-bottom: 4rem;
        padding: 20px;
    }
    .btn button {
        width: 110px;
        height: 35px;
        font-size: 0.75rem;
        font-weight: 600;
    }

    .contant-heading {
        font-size: 22px;

    }
    
    .text-contant {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 137.3%;
        letter-spacing: .015em;
        color: #222;
        padding-bottom: 31px;
    }
}