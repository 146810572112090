.day-heading {
    font-size: 1.5rem;
    font-weight: 600;
    padding-top: 2rem;
}



@media (max-width:1000px) {
    .day-heading {
        font-size: 1.5rem;
        padding-top: 1.7rem;
    }
}

@media (max-width:500px) {
    .day-heading {
        font-size: 1.2rem;
        padding-top: 1.4rem;
    }
}