.headline-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0rem;
}

.headline-subcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 4rem;
    border: 3px solid black;
    padding: 1rem;
    border-radius: 15px;
}

.image-section img {
    width: 410px;
    border-radius: 10px;
    object-fit: cover;
}

.headline-content-section {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
    flex-direction: column;
    height: 100%;
    gap: 1.1rem;
}

.headline-heading {
    font-size: 2rem;
    font-weight: 700;
    padding: 10px 0;

}

.headline-text {
    font-size: 18px;
    line-height: 27px;
}

@media (max-width:1200px) {
    .headline-subcontainer {
        flex-direction: column;
        gap: 2rem;

    }

    .headline-content-section {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 0 40px;
        gap: 1.2rem;

    }

    .image-section img {
        width: 100%;
    }

    .headline-heading {
        font-size: 2rem;
        font-weight: 700;
        padding-bottom: 0px;
        padding-top: 0px;
    }
}

@media (max-width:1000px) {
    .headline-heading {
        font-size: 1.6rem;

    }

    .headline-text {
        font-size: 16px;
        line-height: 27px;
        text-align: justify;
    }
}

@media (max-width:760px) {

    .headline-heading {
        font-size: 1.3rem;

    }

    .headline-text {
        font-size: 15px;
        line-height: 23px;
    }

    .headline-content-section {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 0 30px;
        gap: 1.2rem;

    }
}

@media (max-width:550px) {
    .headline-container {
        margin: 2rem 0rem;
    }

    .headline-heading {
        font-size: 1.1rem;

    }

    .headline-text {
        font-size: 13px;
        line-height: 20px;
    }

    .headline-content-section {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 0 30px;
        gap: 1.2rem;

    }
}

@media (max-width:450px) {
    .headline-container {
        margin: 2rem 0rem;
    }

    .headline-subcontainer
    {
        border: 2px solid black;
    }

    .headline-heading {
        font-size: 1rem;

    }

    .headline-text {
        font-size: 13px;
        line-height: 20px;
    }

    .headline-content-section {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 0 20px;
        gap: 1rem;

    }
}

@media (max-width:370px) {
    .headline-container {
        margin: 1.6rem 0rem;
    }

 

    .headline-content-section {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 0 20px;
        gap: 1rem;

    }
}